import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGlide from "vue-glide-js";

import "@/assets/css/normalize.css";
import "@/assets/css/webflow.css";
import "@/assets/css/wardenswap.webflow.css";
import "vue-glide-js/dist/vue-glide.css";

Vue.config.productionTip = false;

Vue.use(VueGlide);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
